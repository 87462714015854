// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/root/workspace/__flow_work/__flow_builds/2e5c2627-5444-49d9-83e2-2f8de176c9bc/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/@umijs+renderer-react@4.3.35_nnrd3gsncyragczmpvfhocinkq/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
